import React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import FileInput from '../GeneralUse/Input/FileInput'
import InputMUI from '../GeneralUse/Input/InputMUI'
import TeamMemberCard from '../GeneralUse/TeamMemberCard'
import { bigShadow, BLUE, Button, PRIMARY,  } from '../../Assets/Styles'
import user from "../../Assets/Images/user.png"
import { database } from '../../firebase/config'
import { useAuth } from '../../Contexts/AuthContext'
import Loading from '../GeneralUse/Loading'
import UserImageInput from '../GeneralUse/Input/UserImageInput'
import { makeMaskFromFormat } from '@material-ui/pickers/_helpers/text-field-helper'
import translate from '../../i18n/messages/translate'
import Check from '../GeneralUse/Input/Check'


var uniqid = require('uniqid');

const Form = styled.form`
padding-top: 10vh;
    background: white;
    height: fit-content;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95vw;
    position: relative;

    >*{
        margin-top: 30px;
    }

    >input{
        width: 90%;
    }
    

    >a{
        text-decoration: underline;
        color: black;
        font-size: 1.2rem;
        margin-bottom: 15px;
    }
    >h2{
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            color: black;
            height: 10%;
            width: 100%;
            background: ${PRIMARY};
            color: white;
            }
            >img{
                border-radius: 999px;
                object-fit: cover;
                width: 90px !important;
                height: 90px !important;
            }
    @media (min-width: 768px){
        margin: 0;
        position: relative;
        width: 35%;
        padding-top: 7%;
        height: 90vh;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);

        >h2{
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            color: black;
            height: 10%;
            width: 100%;
            background: ${PRIMARY};
            color: white;
            }
            >img{
                border-radius: 999px;
                object-fit: cover;
                width: 90px !important;
                height: 90px !important;
            }
      }
      @media (min-width: 600px) and (orientation: portrait){
        width: 40%;
        max-width: 100%;
        height: 75vh;

      }
    `
export const P = styled.p`
    text-align: center;
    color: black;
    margin-top: 10px;
    `
export const InputContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    >div{
        margin-top: 5%;
    }
>button{
    margin-top: 30px;
}

 .input-file-trigger {
     width: 80%;
  display: block;
  padding: 14px 45px;
  background: rgba(255, 0, 0, 0.25);
  color: #fff;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
  border-radius: 5px;
  :hover{
    background: rgba(255, 0, 0, 0.45);;
  }
}
.input-file {
  position: absolute;
  top: 0; left: 0;
  width: 80%;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}

    `
const Container = styled.div`
 width: 100%;
 height: fit-content;
 padding: 2vh 5vw;
 padding-bottom: 20vh;
 display: flex; 
 flex-direction: column;
 background: ${BLUE};
 align-items: center;
 justify-content: space-between;
 @media (orientation: landscape) and (min-width: 768px){
 height: fit-content;
 flex-direction: row;
 display: flex;
 background: ${BLUE};
 position: relative;
 align-items: center;
 justify-content: space-between;
 padding: 5vh 5vw;

 }

 @media (min-width: 600px) and (orientation: portrait){
    flex-direction: row;
    padding: 1%;
    padding-top: 5vh;
     padding-bottom: 20vh;
 }
 `

const TeamMembersContainer = styled.div`
height: 90vh;
width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-around;
overflow: scroll;
transition: all 0.3s;
margin-top: 10vh;
>div{
    margin-bottom: 50px !important;
}
>h2{
    color: #fff;
    align-self: center;
    justify-self: center;
    margin-left: 10%;
}

@media (orientation: landscape) and (min-width: 768px){
    width: 60%;
    margin-left: 2%;
    margin-top: 0vh;
}
@media (orientation: portrait) and (min-width: 600px){
    width: 50%;
    margin-left: 2%;
    margin-top: 0vh;

        height: 75vh;

      
}
`
const PostButton = styled.button`
width: 90vw;
height: 7vh;
background: ${PRIMARY};
border: none;
font-size: 1.4rem;
bottom: 5vh;
color: white;
box-shadow: ${bigShadow};
border-radius: 10px;
font-weight: 700;
margin-top: 10vh;
transition: 0.2s;
:hover{
     transform: scale(1.1)
 }

 @media (orientation: landscape) and (min-width: 768px){
    font-size: 2rem;
    width: 70vw;
    height: 7%;
    position: absolute;
 }
 @media (min-width: 600px) and (orientation: portrait){
    font-size: 2rem;
    width: 70vw;
    height: 7%;
    position: absolute;
    bottom: -15vh;
 }
`

const getArrayCount = (teamMemberId, array) => {
    let arrayCount
    arrayCount = array.findIndex(element => element.id == teamMemberId)
    return arrayCount
}

let docId = ""

// will export TeamMembersUpload. Used in OrganisationUpload with props teamMembersState, setTeamMembersState
// setTeamMembersState is set to setMembers (which is the state update func for members in parent class OrganisationUpload)

const TeamMembersUpload = ({ teamMembersState, setTeamMembersState }) => {
    //form varibale
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [position, setPosition] = useState("")
    const [link, setLink] = useState("")
    const [id, setId] = useState(uniqid())
    const [memberImage, setMemberImage] = useState("")
    const [order, setOrder] = useState(teamMembersState.length + 1)
    const [president, setPresident] = useState(false)
    const { currentUser } = useAuth()
    //state responsible for editing and deleteing members
    const [editId, setEditId] = useState("")
    const [deleteId, setDeleteId] = useState("")


    //array with all the added memebers


    
    const addUser = (e) => {
        e.preventDefault() // This prevents the default form submission behavior. In React, forms typically trigger a page reload on submission, but this line stops that from happening.
        const teamMembers = teamMembersState // teamMembers is a direct reference to the current state (teamMembersState).
        var teamMembersBefore = Object.assign([], teamMembers); // teamMembersBefore is a shallow copy of teamMembers. This is used to keep a record of the state before modifications.
        teamMembers.push({ name, surname, link, email, position, memberImage, id, president: president, order })
        console.log("teamMembers: ", teamMembers)
        setTeamMembersState(teamMembers)
        setName("") // reset form fields
        setSurname("")
        setEmail("")
        setLink("")
        setMemberImage("")
        var updatedOrder = teamMembersBefore.find(key => key.id === id) ? teamMembers.length : teamMembers.length + 1
        setOrder(updatedOrder)
        setPosition("")
        setPresident(false)
        setId(uniqid())
        if (editId) {
            setDeleteId(editId)
            setEditId("")
        }
    }

    const deleteMember = (memberId) => {
        let place = teamMembersState
        let memberCount = getArrayCount(memberId, teamMembersState)
        place.splice(memberCount, 1);
        setTeamMembersState(place)
    }

    const editMember = (memberId) => {
        
        let memberCount = getArrayCount(memberId, teamMembersState)
        console.log("editMember for thisMember: ", teamMembersState[memberCount].id, teamMembersState[memberCount].order)
        setName(teamMembersState[memberCount].name)
        setSurname(teamMembersState[memberCount].surname)
        setEmail(teamMembersState[memberCount].email)
        setLink(teamMembersState[memberCount].link)
        setPosition(teamMembersState[memberCount].position)
        setPresident(teamMembersState[memberCount].president)
        setMemberImage(teamMembersState[memberCount].memberImage)
        setOrder(teamMembersState[memberCount].order)
        setId(teamMembersState[memberCount].id)
    }
    //editing a user. Effect will run (i.e. the function as first argument is passed), whenever a variable of the array in second argument is changed.
    useEffect(() => {
        console.log("useEffect for editId: ", editId)
        if (editId) {
            editMember(editId)
        }
    }, [editId])


    //deleting a user
    useEffect(() => {
        if (deleteId) {
            deleteMember(deleteId)
        }
        setDeleteId("")
    }, [deleteId])

    return (

        <Container>
            <Form onSubmit={(e) => addUser(e)}>
                <h2 style={{ marginBottom: "20px" }}>{translate("add team member")}</h2>
                <UserImageInput setUserImageUrl={setMemberImage} userImageUrl={memberImage}></UserImageInput>
                <InputContainer>
                    <InputMUI value={name} setValue={setName} label={translate("name")}></InputMUI>
                    <InputMUI value={surname} setValue={setSurname} label={translate("surname")}></InputMUI>
                    <InputMUI value={email} setValue={setEmail} label="Email"></InputMUI>
                    <InputMUI value={position} setValue={setPosition} label={translate("Position")}></InputMUI>
                    {/* <InputMUI value={link} setValue={setLink} label="Link"></InputMUI> */}
                    <InputMUI value={order} setValue={setOrder} label={translate("Reihenfolge")}></InputMUI>
                    <Check selected={president} setSelected={setPresident} color={PRIMARY}></Check>

                    <Button bigger onClick={(e) => addUser(e)}>{translate("add")}</Button>
                </InputContainer>

                {/* <Button onClick={()=>setStartHandleImages(true)} bigger={true}  color="${PRIMARY}" disabled={loading} type="submit">Upload</Button>  */}
            </Form>
            {
                teamMembersState && teamMembersState.length > 0 ?
                    <TeamMembersContainer>
                        {teamMembersState.sort((b, a) => a.order < b.order ? 1 : -1).map(element =>
                            <TeamMemberCard
                                setEditId={setEditId}
                                setDeleteId={setDeleteId}
                                name={element.name}
                                surname={element.surname}
                                link={element.link}
                                email={element.email}
                                position={element.position}
                                memberImage={element.memberImage}
                                order={element.order}
                                edit={true}
                                id={element.id}
                                >
                            </TeamMemberCard>)}
                    </TeamMembersContainer>
                    :
                    <TeamMembersContainer><h2>{translate("You do not have any Team Members added yet")}</h2></TeamMembersContainer>
            }

        </Container>
    )
}


export default TeamMembersUpload
